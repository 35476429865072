<script>
    import { styles } from '../../utils/styles.js';
    import { classFactory, tagsFactory } from '../../utils/factories';
    import { afterUpdate } from 'svelte';

    const { colors, sizes, fonts, backgroundColor, text, borderRadius } = styles;

    export let color = 'black';
    export let size = 'normal';
    export let family = fonts.fontFamily;
    export let weight = 'normal';
    export let align = 'left';
    export let as = 'p';
    export let background = backgroundColor.white;
    export let radius = borderRadius.rounded;

    let tags = {};
    afterUpdate(() => {
        const result = classFactory(
                sizes[size],
                colors[color],
                family,
                fonts.weight[weight],
                backgroundColor[background],
                text[align],
        );
        tags = tagsFactory({ result, borderRadius: radius, ...$$props });
    });
</script>

<style>@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap");
</style>

{@html tags[as]}
<slot/>
