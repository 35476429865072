<script>
    import Root from './components/Root.svelte';
    import Paper from './components/atoms/Paper.svelte';
    import { setupI18n, isLoading } from './utils/i18n';
    setupI18n({ withLocale: window.navigator.language });
</script>

<style>:global(body) {
  padding: 0;
  height: 100%; }

:global(*) {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }
</style>
<Paper flex h="100%" align="center" py="50px">
	{#if !$isLoading}
        <Root>
            <slot/>
        </Root>
	{/if}
</Paper>
